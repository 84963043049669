<template>
  <div class="address-page">
    <headerBar :title="'USDT管理'" :showArrow="true" @back="back"></headerBar>

   <div class="address-list">
      <div class="list small flex-center-between" v-for="(item, i) in addressList" :key="i" @click="toDetail(item)">
        <div class="icon flex-column-center">
          <img :src="bitUrl1" class="big" alt="">
        </div>
        <div class="card flex-1">
          <div>BTC</div>
          <div>充值5次，提现3次</div>
        </div>
        <div class="arrow">
          <img :src="arrowUrl" alt="">
        </div>
      </div>

      <div class="empty-list" v-if="addressList.length == 0">请绑定银行卡</div>

      <div class="add-line flex-center" @click="toAdd">
        <img :src="addUrl" alt="">
        <span>添加USDT</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      addUrl: require('../../assets/imgs/icon-add.png'),
      arrowUrl: require('../../assets/imgs/icon-arrow-right.png'),
      bitUrl1: require('../../assets/imgs/bit1.png'),
      bitUrl2: require('../../assets/imgs/bit2.png'),
      bitUrl3: require('../../assets/imgs/bit3.png'),
      bitUrl4: require('../../assets/imgs/bit4.png'),
      addressList: [{}, {}, {}],
    }
  },
  mounted() {

  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    toAdd() {
      this.$router.push({
        name: 'bind'
      })
    },
    toDetail(data) {
      this.$router.push({
        name: 'usdtDetail',
        query: {
          id: data.id
        }
      })
    }
  }
}
</script>